import _ from 'lodash';

import React, {Component} from 'react';
import {connect} from 'react-redux';

import BottombarTitle from '../Bottombar.Title.jsx';
import ButtonIcon from 'Widgets/ButtonIcon.jsx';
import { Loading } from 'Widgets/Loading.jsx';
import VTT from './VTTEditor.jsx'
import TabnavItem from  '../Tabnav.item.jsx';

import {openModal} from 'actions/app';
import {itemAnalysisLobUpdate} from '../actions/index'

import { LOB_KEYS, PROGRESS_FLAGS, TRANSCRIBE_LANGUAGES } from 'Constants/app.js'

class VTTWrapper extends Component {

	constructor(props) {
        super(props);
        
        this.state = {
            activeTabIdx: false
        }
    }
    
	render() {
        const { 
            locked, 
            isItemAnalysisOwner, 
            isShare,
            item,
            itemAnalysisID, 
        } = this.props;
    
        // itemAnalysisLOBs and/or itemAnalysisID not yet loaded
        if( undefined === item ||
            undefined === itemAnalysisID ) return <Loading />

        let {activeTabIdx} = this.state;
        const maxHeight = this.props.bottombarHeight-100
        
		/**
		 * transcriptionsProgress is a map of all transcription requests with their progress status. 
		 * VTT's us the same flag since the cloud generation the VTT is part of the same Transcribe job that creates the JSON
		 */
        const transcriptionsProgress = item.transcribeProgress || {};

        // Build tabs & tab contents
        var tabs = [];     // Stores tab titles for BottombarTitle
        var editors = [];  // Stores a quill editor (<VTT />) for each

        // Using Transcribe launguages here because it is already alpha sorted on written language 'French Canadian'
        _.forEach(TRANSCRIBE_LANGUAGES, (val, idx) => {  //idx is langugage code in format en-US, v is the written lang 'English US'
            
            // Does transcription exist for this item?
            if(undefined === transcriptionsProgress[idx]) return; // for _.forEach(), return is like continue; retrun false to break;
            
            // Get progress indicator
            const progress = transcriptionsProgress[idx]
            
            // Ignore errors
            if( PROGRESS_FLAGS.ERROR === progress ) return; 

            // If activeTab not set, set to first item that catches it
            if( false === activeTabIdx ) activeTabIdx = idx;

            tabs.push(
                <TabnavItem
                    active={activeTabIdx === idx}
                    key={_.snakeCase(val)+'-'+idx}
                    index={idx}
                    text={val}
                    onItemClick={this.handleTabNavItemClick}
                />)

            editors.push(
                PROGRESS_FLAGS.INPROGRESS === progress 
                ? <div className="text-success"
                    key={idx}
                    style={{display: activeTabIdx === idx ? 'inline' : 'none'}}
                    >
                    <strong><i>{`${val} transcription in progress...`}</i></strong>
                    </div>
                : <VTT
                    key={idx}
                    active={activeTabIdx === idx}
                    itemAnalysisID={itemAnalysisID}
                    isItemAnalysisOwner={isItemAnalysisOwner}
                    locked={locked}
                    maxHeight={maxHeight}
                    vttKey={LOB_KEYS.VTT + idx}
                    />               
            )
        });

		return (
            <div>
                <BottombarTitle
                    activeTab='vtt' 
                    isItemAnalysisOwner={isItemAnalysisOwner}
                    isShare={isShare}
                    handleOnManageBtnClick={this.handleManageVTTClick}
                    locked={locked}
                    title = 'VTT'
                    >
                    { tabs.length > 0
                    ? <ul className='nav nav-tabs'>{tabs}</ul>
                    : null
                    }
                </BottombarTitle> 
                { // Only render generate vtt button if no vtt's exist
                    tabs.length > 0
                    ? <div
                        className="col-12 editor"
                        id="quillContainer"
                        style={{
                            maxHeight: maxHeight,
                            height: 'auto'
                        }}>
                        {editors}
                        </div>
                    : this.renderManageVTTsButton(isItemAnalysisOwner, isShare)
                }       
            </div>
		);
    }

    /**
     * Renders large Manage VTTs button or no transcriptions message if !isItemAnalysisOwner
     */
    renderManageVTTsButton =(isItemAnalysisOwner, isShare) => {
        // TODO: isShare is Tmp addition to prevent transcribing of shared items
        if(isItemAnalysisOwner && !isShare)
            return (
                <ButtonIcon
                    style={{marginTop: '.7rem'}}
                    btnClass="dataset-upload-button btn btn-outline-secondary"
                    iconClass="far fa-bullhorn"
                    label='Manage VTTs'
                    onClick={this.handleManageVTTClick}
            /> );
        else 
            return (<small style={{padding: '.7rem .2rem' }}>No closed captions.</small>)
    }

    /**
     * Handles clicking of manage VTT button both in this component & <BottombarTitle />
     */
	handleManageVTTClick = e => {
		e.preventDefault();
		e.stopPropagation();
		this.props.openModal('transcribeModal', '');
    }
    
    /**
     * Handles changing of current active tab when tab is clicked
     */
	handleTabNavItemClick = ix => {
		this.setState({
			activeTabIdx: ix
		});
	};
}

const mapStateToProps = state => {
	return {
        bottombarHeight: state.app.bottombarHeight,
        item: state.folderItems.selectedItem,
	};
};

const mapDispatchToProps = {
    openModal,
    itemAnalysisLobUpdate,
}

export default connect(mapStateToProps, mapDispatchToProps)(VTTWrapper);
